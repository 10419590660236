<template>
	<div>
		<v-card
			rounded="xl"
			class="ma-4"
		>
			<v-card-title>
				This is events sidebar
			</v-card-title>
		</v-card>
		<v-divider class="mt-3" />
	</div>
</template>

<script>
export default {
	name: "Sidebar"
}
</script>

<style scoped>

</style>
